import React from 'react';
import { Box, Card, CardContent, Grid, Typography, Chip, Link } from '@mui/material';
import { PhotoCamera, Launch } from '@mui/icons-material';
import { Observation } from '../types/models';
import { format } from 'date-fns';

interface ObservationRowProps {
  observation: Observation;
  onSpeciesClick?: (scientificName: string) => void;
}

const importanceLabels = {
  1: 'Normal',
  2: 'Important',
  3: 'Very Important'
} as const;

const importanceColors = {
  1: undefined,
  2: "primary" as const,
  3: "error" as const
};

const ObservationRow: React.FC<ObservationRowProps> = ({ observation, onSpeciesClick }) => {
  const mainSpecies = observation.species[0];
  const secondarySpecies = observation.species.slice(1);

  const hasAnyImages = observation.internal_image || observation.external_image_url;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                cursor: onSpeciesClick ? 'pointer' : 'inherit',
                '&:hover': onSpeciesClick ? {
                  textDecoration: 'underline',
                  color: 'primary.main'
                } : {}
              }}
              onClick={() => onSpeciesClick?.(mainSpecies?.name_sci)}
            >
              {mainSpecies?.name_fi || mainSpecies?.name_en || mainSpecies?.name_sci}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {[
                mainSpecies?.name_en || '',
                mainSpecies?.name_sci || ''
              ]
                .filter(Boolean)
                .join(' • ')}
            </Typography>
            {mainSpecies?.name_abbr && (
              <Typography variant="body2" color="text.secondary">
                {mainSpecies.name_abbr}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
              <Chip
                size="small"
                label={importanceLabels[observation.importance]}
                color={importanceColors[observation.importance]}
              />
              {observation.gender !== 'unknown' && (
                <Chip size="small" label={observation.gender} />
              )}
              {observation.age !== 'unknown' && (
                <Chip size="small" label={observation.age} />
              )}
              {observation.plumage !== 'unknown' && (
                <Chip size="small" label={observation.plumage} />
              )}
              {observation.is_weird && (
                <Chip size="small" label="Weird" color="warning" />
              )}
            </Box>

            {hasAnyImages && (
              <Box sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                mb: 2,
                fontSize: '0.875rem',
                color: 'text.secondary'
              }}>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  Images:
                </Typography>
                {observation.internal_image && (
                  <Link
                    href={observation.internal_image.image}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                      textDecoration: 'none',
                      color: 'primary.main',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    <PhotoCamera sx={{ fontSize: 16 }} />
                    <Typography variant="body2">Internal Image</Typography>
                  </Link>
                )}
                {observation.external_image_url && (
                  <Link
                    href={observation.external_image_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                      textDecoration: 'none',
                      color: 'primary.main',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    <Launch sx={{ fontSize: 16 }} />
                    <Typography variant="body2">External Image</Typography>
                  </Link>
                )}
              </Box>
            )}

            <Typography variant="body2">
              {observation.place}, {observation.country_name} •
              {format(new Date(observation.start_date), 'dd.MM.yyyy')}
              {observation.end_date && ` - ${format(new Date(observation.end_date), 'dd.MM.yyyy')}`}
              {observation.coordinates && ` • ${observation.coordinates}`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Observer: {observation.observer_name}
              {observation.source && ` • Source: ${observation.source}`}
            </Typography>
            {observation.notes && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                {observation.notes}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ObservationRow;