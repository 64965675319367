import React from 'react';
import { Box, Card, Grid, Typography, Chip } from '@mui/material';
import { SoundSet } from '../types/models';
import { format } from 'date-fns';
import AudioPlayer from './AudioPlayer';

interface SoundSetRowProps {
  soundSet: SoundSet;
  globalVolume: number;
  onSpeciesClick?: (scientificName: string) => void;
}

const SoundSetRow: React.FC<SoundSetRowProps> = ({ soundSet, globalVolume, onSpeciesClick }) => {
  const mainSpecies = soundSet.species[0];

  return (
    <Card sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box>
              <Typography
                variant="h6"
                onClick={() => onSpeciesClick?.(mainSpecies?.name_sci)}
                sx={{
                  cursor: onSpeciesClick ? 'pointer' : 'inherit',
                  mb: 0.5,
                  lineHeight: 1,
                  '&:hover': onSpeciesClick ? {
                    textDecoration: 'underline',
                    color: 'primary.main'
                  } : {}
                }}
              >
                {mainSpecies?.name_fi || mainSpecies?.name_en || mainSpecies?.name_sci}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
                {[
                  mainSpecies?.name_en || '',
                  mainSpecies?.name_sci || '',
                  mainSpecies?.name_abbr || ''
                ]
                  .filter(Boolean)
                  .join(' • ')}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                <Chip size="small" label={soundSet.sound_type} color="primary" />
                {soundSet.gender !== 'unknown' && <Chip size="small" label={soundSet.gender} />}
                {soundSet.age !== 'unknown' && <Chip size="small" label={soundSet.age} />}
                {soundSet.plumage !== 'unknown' && <Chip size="small" label={soundSet.plumage} />}
                {soundSet.is_weird && <Chip size="small" label="Weird" color="warning" />}
              </Box>

              <Typography variant="body2" sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
                <span>{soundSet.place}, {soundSet.country_name}</span>
                <span>•</span>
                <span>{format(new Date(soundSet.date_recorded), 'dd.MM.yyyy')}</span>
                {soundSet.coordinates && (
                  <>
                    <span>•</span>
                    <span>{soundSet.coordinates}</span>
                  </>
                )}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {soundSet.sounds.map((sound, index) => (
              <AudioPlayer
                key={sound.id}
                src={sound.audio}
                title={`Recording ${index + 1}`}
                globalVolume={globalVolume}
              />
            ))}
          </Box>
        </Grid>

        {(soundSet.recorder_name || soundSet.equipment || soundSet.notes) && (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 1, color: 'text.secondary' }}>
              {soundSet.recorder_name && <Typography variant="body2">©{soundSet.recorder_name}</Typography>}
              {soundSet.equipment && (
                <>
                  <Typography variant="body2">•</Typography>
                  <Typography variant="body2">{soundSet.equipment}</Typography>
                </>
              )}
              {soundSet.notes && (
                <>
                  <Typography variant="body2">•</Typography>
                  <Typography variant="body2">{soundSet.notes}</Typography>
                </>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default SoundSetRow;