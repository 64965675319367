import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { getObservations } from '../api/observation';
import ObservationRow from '../components/ObservationRow';
import { SharedFilterBox } from '../components/SharedFilterBox';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

interface FormValues {
  species: string;
  place: string;
  gender: '' | 'male' | 'female' | 'unknown';
  age: '' | 'adult' | 'juvenile' | 'immature' | 'unknown';
  importance: number | '';
  date_from: Dayjs | null;
  date_to: Dayjs | null;
  countries: string[];
  status: string;
  certainty: string;
  plumage: '' | 'breeding' | 'non-breeding' | 'eclipse' | 'first_winter' | 'second_winter' | 'first_summer' | 'unknown';
  ordering: string;
  month: string;
  day: string;
  day_from: string;
  day_to: string;
  month_from: string;
  month_to: string;
  year_from: string;
  year_to: string;
}

const ObservationSets: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValues: FormValues = {
    species: searchParams.get('species') || '',
    place: searchParams.get('place') || '',
    gender: (searchParams.get('gender') || '') as '' | 'male' | 'female' | 'unknown',
    age: (searchParams.get('age') || '') as '' | 'adult' | 'juvenile' | 'immature' | 'unknown',
    importance: searchParams.get('importance') ? Number(searchParams.get('importance')) : '',
    date_from: searchParams.get('date_from') ? dayjs(searchParams.get('date_from')) : null,
    date_to: searchParams.get('date_to') ? dayjs(searchParams.get('date_to')) : null,
    countries: searchParams.getAll('countries'),
    status: searchParams.get('status') || '',
    certainty: searchParams.get('certainty') || '',
    plumage: (searchParams.get('plumage') || '') as '' | 'breeding' | 'non-breeding' | 'eclipse' | 'first_winter' | 'second_winter' | 'first_summer' | 'unknown',
    ordering: searchParams.get('ordering') || '-created_at',
    month: searchParams.get('month') || '',
    day: searchParams.get('day') || '',
    day_from: searchParams.get('day_from') || '',
    day_to: searchParams.get('day_to') || '',
    month_from: searchParams.get('month_from') || '',
    month_to: searchParams.get('month_to') || '',
    year_from: searchParams.get('year_from') || '',
    year_to: searchParams.get('year_to') || ''
  };

  const { control, handleSubmit, reset, setValue } = useForm<FormValues>({
    defaultValues
  });

  const handleSpeciesClick = (scientificName: string) => {
    setValue('species', scientificName);
    setSearchParams(prev => {
      prev.set('species', scientificName);
      return prev;
    });
  };

  const handleReset = () => {
    reset(defaultValues);
    setSearchParams(new URLSearchParams());
  };

  const onSubmit = (formData: FormValues) => {
    const params = new URLSearchParams();

    Object.entries(formData).forEach(([key, value]) => {
      if (value !== undefined && value !== '' && value !== null) {
        if (key === 'date_from' || key === 'date_to') {
          const dateValue = value as Dayjs;
          if (dateValue) {
            params.set(key, dateValue.format('YYYY-MM-DD'));
          }
        } else if (key === 'countries' && Array.isArray(value)) {
          value.forEach(countryName => {
            if (countryName) {
              params.append('countries', countryName);
            }
          });
        } else {
          params.set(key, String(value));
        }
      }
    });

    setSearchParams(params);
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['observations', Object.fromEntries(searchParams)],
    queryFn: () => getObservations({
      species: searchParams.get('species') || undefined,
      place: searchParams.get('place') || undefined,
      gender: searchParams.get('gender') as any || undefined,
      age: searchParams.get('age') as any || undefined,
      plumage: searchParams.get('plumage') as any || undefined,
      date_from: searchParams.get('date_from') || undefined,
      date_to: searchParams.get('date_to') || undefined,
      countries: searchParams.getAll('countries'),
      ordering: searchParams.get('ordering') || undefined,
      status: searchParams.get('status') || undefined,
      certainty: searchParams.get('certainty') || undefined,
      month: searchParams.get('month') ? Number(searchParams.get('month')) : undefined,
      day: searchParams.get('day') ? Number(searchParams.get('day')) : undefined,
      day_from: searchParams.get('day_from') ? Number(searchParams.get('day_from')) : undefined,
      day_to: searchParams.get('day_to') ? Number(searchParams.get('day_to')) : undefined,
      month_from: searchParams.get('month_from') ? Number(searchParams.get('month_from')) : undefined,
      month_to: searchParams.get('month_to') ? Number(searchParams.get('month_to')) : undefined,
      year_from: searchParams.get('year_from') ? Number(searchParams.get('year_from')) : undefined,
      year_to: searchParams.get('year_to') ? Number(searchParams.get('year_to')) : undefined
    })
  });

  return (
    <Box>
      <Box sx={{
        height: { xs: '200px', sm: '300px' },
        backgroundImage: 'url(/images/havainnot.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center 30%',
      }} />

      <Container maxWidth="lg" sx={{ mt: { xs: -4, sm: -6 } }}>
        <SharedFilterBox
          title="Haku"
          onSubmit={handleSubmit(onSubmit)}
          onReset={handleReset}
          control={control}
          showObserverFilter
        />

        <Box sx={{ mt: 4 }}>
          {isLoading ? (
            <Typography>Loading...</Typography>
          ) : error ? (
            <Typography color="error">Error loading results</Typography>
          ) : data?.results.length === 0 ? (
            <Typography>No results found</Typography>
          ) : (
            <Grid container spacing={2} direction="column">
              {data?.results.map(observation => (
                <Grid item key={observation.id}>
                  <ObservationRow
                    observation={observation}
                    onSpeciesClick={handleSpeciesClick}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ObservationSets;