import axios from 'axios';
import type { TaxonomySearchResponse } from '../types/models';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const searchTaxonomies = async (searchTerm: string): Promise<TaxonomySearchResponse> => {
    const params = new URLSearchParams();
    if (searchTerm) {
        params.append('search', searchTerm);
    }

    const response = await axios.get<TaxonomySearchResponse>(`${BASE_URL}/api/taxonomies/`, {
        params,
        headers: {
            'Accept': 'application/json',
        }
    });
    return response.data;
};