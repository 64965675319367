import { useQuery } from '@tanstack/react-query';
import { searchCountries } from '../api/countries';

export const useSearchCountries = (searchTerm: string) => {
    return useQuery({
        queryKey: ['countries', searchTerm],
        queryFn: () => searchCountries(searchTerm),
        enabled: searchTerm.length > 2,
        staleTime: 5 * 60 * 1000,
    });
};