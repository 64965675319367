import axios from 'axios';
import type { CountrySearchResponse } from '../types/models';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const searchCountries = async (searchTerm: string): Promise<CountrySearchResponse> => {
    const params = new URLSearchParams();
    if (searchTerm) {
        params.append('search', searchTerm);
    }

    const response = await axios.get<CountrySearchResponse>(`${BASE_URL}/api/countries/`, {
        params,
        headers: {
            'Accept': 'application/json',
        }
    });
    return response.data;
};