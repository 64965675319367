import React, { useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Grid,
    TextField,
    MenuItem,
    Button,
    Typography,
    IconButton,
    Collapse,
    Autocomplete,
    Chip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { Controller } from 'react-hook-form';
import { useSearchTaxonomies } from '../hooks/useSearchTaxonomies';
import { useSearchCountries } from '../hooks/useSearchCountries';
import { Taxonomy, Country } from '../types/models';
import { useSearchParams } from 'react-router-dom';

export const GENDER_OPTIONS = [
    { value: 'male', label: 'Koiras' },
    { value: 'female', label: 'Naaras' },
    { value: '', label: 'Kaikki' }
];

export const AGE_OPTIONS = [
    { value: 'chick', label: 'Poikanen' },
    { value: 'juvenile', label: 'Nuori' },
    { value: 'subadult', label: 'Esiaikuinen' },
    { value: 'adult', label: 'Vanha' },
    { value: 'nest', label: 'Pesä' },
];

export const PLUMAGE_OPTIONS = [
    { value: 'breeding', label: 'Pesimäpuku' },
    { value: 'non-breeding', label: 'Talvipuku' },
    { value: 'eclipse', label: 'Eclipse' },
    { value: 'first_winter', label: 'Ensimmäinen talvipuku' },
    { value: 'second_winter', label: 'Toinen talvipuku' },
    { value: 'first_summer', label: 'Ensimmäinen kesäpuku' },
];

export const ORDER_OPTIONS = [
    { value: '-created_at', label: 'Lisäyspäivä (uusin)' },
    { value: 'created_at', label: 'Lisäyspäivä (vanhin)' },
    { value: '-date_taken', label: 'Kuvauspäivä (uusin)' },
    { value: 'date_taken', label: 'Kuvauspäivä (vanhin)' },
    { value: 'species__name_sci', label: 'Tieteellinen nimi (A-Z)' },
    { value: '-species__name_sci', label: 'Tieteellinen nimi (Z-A)' },
    { value: 'species__name_fi', label: 'Suomenkielinen nimi (A-Z)' },
    { value: '-species__name_fi', label: 'Suomenkielinen nimi (Z-A)' },
];

export const WEIRD_OPTIONS = [
    { value: 'true', label: 'Kyllä' },
    { value: 'false', label: 'Ei' },
    { value: '', label: 'Kaikki' }
];


interface SharedFilterBoxProps {
    title: string;
    onSubmit: (event: React.FormEvent) => void;
    onReset: () => void;
    control: any;
    children?: React.ReactNode;
    showPhotoMethodFilter?: boolean;
    showPhotographerFilter?: boolean;
    showRecorderFilter?: boolean;
    showObserverFilter?: boolean;
}

export const SharedFilterBox: React.FC<SharedFilterBoxProps> = ({
    title,
    onSubmit,
    onReset,
    control,
    children,
    showPhotoMethodFilter,
    showPhotographerFilter,
    showRecorderFilter,
    showObserverFilter
}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [taxonomySearch, setTaxonomySearch] = useState('');
    const [countrySearch, setCountrySearch] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const { data: taxonomyData, isLoading: isTaxonomyLoading } = useSearchTaxonomies(taxonomySearch);
    const { data: countryData, isLoading: isCountryLoading } = useSearchCountries(countrySearch);

    const handleDejavuClick = () => {
        const today = new Date();
        const currentDay = today.getDate();
        const currentMonth = today.getMonth() + 1;
        const params = new URLSearchParams(searchParams);
        params.set('day_from', currentDay.toString());
        params.set('day_to', currentDay.toString());
        params.set('month_from', currentMonth.toString());
        params.set('month_to', currentMonth.toString());
        setSearchParams(params);
    };

    return (
        <Card sx={{ mb: 4 }}>

            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">{title}</Typography>
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <ChevronUp /> : <ChevronDown />}
                    </IconButton>
                </Box>

                <Collapse in={isExpanded}>
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Controller
                                    name="species"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Autocomplete
                                            options={taxonomyData?.results || []}
                                            getOptionLabel={(option: Taxonomy | string) =>
                                                typeof option === 'string'
                                                    ? option
                                                    : `${option.name_sci}${option.name_fi ? ` (${option.name_fi})` : ''}`
                                            }
                                            value={value || null}
                                            onChange={(_, newValue) => {
                                                onChange(typeof newValue === 'string' ? newValue : newValue?.name_sci || '');
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                setTaxonomySearch(newInputValue);
                                            }}
                                            loading={isTaxonomyLoading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Laji"
                                                    size="small"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Controller
                                    name="countries"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Autocomplete
                                            multiple
                                            options={countryData?.results || []}
                                            getOptionLabel={(option: Country | string) =>
                                                typeof option === 'string' ? option : option.name
                                            }
                                            value={value || []}
                                            onChange={(_, newValue: (Country | string)[]) => {
                                                const countryNames = newValue.map(item =>
                                                    typeof item === 'string' ? item : item.name
                                                );
                                                onChange(countryNames);
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                setCountrySearch(newInputValue);
                                            }}
                                            loading={isCountryLoading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Maat"
                                                    size="small"
                                                    fullWidth
                                                />
                                            )}
                                            isOptionEqualToValue={(option: Country, value: Country | string) => {
                                                if (typeof value === 'string') {
                                                    return option.name === value;
                                                }
                                                return option.id === value.id;
                                            }}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((option, index) => (
                                                    <Chip
                                                        label={typeof option === 'string' ? option : option.name}
                                                        {...getTagProps({ index })}
                                                        size="small"
                                                    />
                                                ))
                                            }
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Controller
                                    name="place"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Paikka"
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Controller
                                    name="gender"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Sukupuoli"
                                            fullWidth
                                            size="small"
                                        >
                                            <MenuItem value="">Kaikki</MenuItem>
                                            {GENDER_OPTIONS.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Controller
                                    name="age"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Ikä"
                                            fullWidth
                                            size="small"
                                        >
                                            <MenuItem value="">Kaikki</MenuItem>
                                            {AGE_OPTIONS.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Controller
                                    name="ordering"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Järjestys"
                                            fullWidth
                                            size="small"
                                        >
                                            {ORDER_OPTIONS.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Grid>

                            {showPhotoMethodFilter && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <Controller
                                        name="photo_method"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                label="Kuvaus tapa"
                                                fullWidth
                                                size="small"
                                            >
                                                <MenuItem value="">Kaikki</MenuItem>
                                                <MenuItem value="field">Maastokuva</MenuItem>
                                                <MenuItem value="handheld">Käsikuva</MenuItem>
                                            </TextField>
                                        )}
                                    />
                                </Grid>
                            )}

                            {(showPhotographerFilter || showRecorderFilter || showObserverFilter) && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <Controller
                                        name={showPhotographerFilter ? "photographer" : showRecorderFilter ? "recorder" : "observer"}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label={showPhotographerFilter ? "Kuvaaja" : showRecorderFilter ? "Äänittäjä" : "Havainnoija"}
                                                fullWidth
                                                size="small"
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={6} md={4}>
                                <Controller
                                    name="is_weird"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Kummajaiset"
                                            fullWidth
                                            size="small"
                                        >
                                            {WEIRD_OPTIONS.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="day_from"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="number"
                                                    label="Päivä"
                                                    fullWidth
                                                    size="small"
                                                    InputProps={{ inputProps: { min: 1, max: 31 } }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="month_from"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="number"
                                                    label="Kuukausi"
                                                    fullWidth
                                                    size="small"
                                                    InputProps={{ inputProps: { min: 1, max: 12 } }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="year_from"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="number"
                                                    label="Vuosi"
                                                    fullWidth
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="day_to"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="number"
                                                    label="Päivä"
                                                    fullWidth
                                                    size="small"
                                                    InputProps={{ inputProps: { min: 1, max: 31 } }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="month_to"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="number"
                                                    label="Kuukausi"
                                                    fullWidth
                                                    size="small"
                                                    InputProps={{ inputProps: { min: 1, max: 12 } }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="year_to"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="number"
                                                    label="Vuosi"
                                                    fullWidth
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {children}

                            <Grid item xs={12} sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                                <Button
                                    size="small"
                                    onClick={handleDejavuClick}
                                >
                                    Dejavu
                                </Button>
                                <Button onClick={onReset}>
                                    Tyhjennä
                                </Button>
                                <Button variant="contained" type="submit">
                                    Hae
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Collapse>
            </CardContent>
        </Card>
    );
};