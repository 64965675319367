import { useQuery } from '@tanstack/react-query';
import { searchTaxonomies } from '../api/taxanomy';

export const useSearchTaxonomies = (searchTerm: string) => {
    return useQuery({
        queryKey: ['taxonomies', searchTerm],
        queryFn: () => searchTaxonomies(searchTerm),
        enabled: searchTerm.length > 2,
        staleTime: 5 * 60 * 1000,
    });
};