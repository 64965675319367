import axios from 'axios';
import { Statistics } from '../types/models';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';


export const getStatistics = async (): Promise<Statistics> => {
    try {
        const response = await axios.get<Statistics>(`${BASE_URL}/api/statistics/stats/`, {
            headers: {
                'Accept': 'application/json',
            }
        });

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('API Error:', {
                message: error.message,
                status: error.response?.status,
                data: error.response?.data
            });
        }
        throw error;
    }
};