import React, { useState } from 'react';
import { Box, Grid, Typography, Chip, Card } from '@mui/material';
import { ImageSet } from '../types/models';
import { format } from 'date-fns';
import ImageViewer from './ImageViewer';

interface ImageSetRowProps {
  imageSet: ImageSet;
  onSpeciesClick?: (scientificName: string) => void;
}

const ImageSetRow: React.FC<ImageSetRowProps> = ({ imageSet, onSpeciesClick }) => {
  const [viewerOpen, setViewerOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
    setViewerOpen(true);
  };

  const handleClose = () => setViewerOpen(false);
  const handleNext = () => setCurrentImageIndex(prev =>
    prev === imageSet.images.length - 1 ? 0 : prev + 1
  );
  const handlePrevious = () => setCurrentImageIndex(prev =>
    prev === 0 ? imageSet.images.length - 1 : prev - 1
  );

  const mainSpecies = imageSet.species[0];

  return (
    <>
      <Card sx={{ p: 2, width: '100%' }}>
        <Grid container spacing={2} sx={{ overflow: 'hidden' }}>
          <Grid item xs={12}>
            <Box sx={{
              overflowX: 'auto',
              WebkitOverflowScrolling: 'touch',
              mx: -2,
              px: 2
            }}>
              <Box sx={{
                display: 'flex',
                gap: 2,
                width: 'max-content'
              }}>
                {imageSet.images.map((image, index) => (
                  <Box
                    key={image.id}
                    onClick={() => handleImageClick(index)}
                    sx={{
                      width: 200,
                      height: 150,
                      flexShrink: 0,
                      backgroundImage: `url(${image.image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      borderRadius: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        opacity: 0.9,
                        transform: 'scale(1.02)',
                        transition: 'all 0.2s ease-in-out'
                      }
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box>
                <Typography
                  variant="h6"
                  onClick={() => onSpeciesClick?.(mainSpecies?.name_sci)}
                  sx={{
                    cursor: 'pointer',
                    mb: 0.5,
                    lineHeight: 1
                  }}
                >
                  {mainSpecies?.name_fi || mainSpecies?.name_en || mainSpecies?.name_sci}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
                  {[
                    mainSpecies?.name_en || '',
                    mainSpecies?.name_sci || '',
                    mainSpecies?.name_abbr || ''
                  ]
                    .filter(Boolean)
                    .join(' • ')}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {imageSet.gender !== 'unknown' && <Chip size="small" label={imageSet.gender} />}
                  {imageSet.age !== 'unknown' && <Chip size="small" label={imageSet.age} />}
                  {imageSet.plumage !== 'unknown' && <Chip size="small" label={imageSet.plumage} />}
                  {imageSet.is_weird && <Chip size="small" label="Weird" color="warning" />}
                </Box>

                <Typography variant="body2" sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
                  <span>{imageSet.place}, {imageSet.country_name}</span>
                  <span>•</span>
                  <span>{format(new Date(imageSet.date_taken), 'dd.MM.yyyy')}</span>
                  {imageSet.coordinates && (
                    <>
                      <span>•</span>
                      <span>{imageSet.coordinates}</span>
                    </>
                  )}
                </Typography>
              </Box>

              {(imageSet.photographer_name || imageSet.equipment || imageSet.notes) && (
                <Box sx={{ display: 'flex', gap: 1, color: 'text.secondary' }}>
                  {imageSet.photographer_name && <Typography variant="body2">©{imageSet.photographer_name}</Typography>}
                  {imageSet.equipment && (
                    <>
                      <Typography variant="body2">•</Typography>
                      <Typography variant="body2">{imageSet.equipment}</Typography>
                    </>
                  )}
                  {imageSet.notes && (
                    <>
                      <Typography variant="body2">•</Typography>
                      <Typography variant="body2">{imageSet.notes}</Typography>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Card>

      <ImageViewer
        open={viewerOpen}
        onClose={handleClose}
        images={imageSet.images}
        currentIndex={currentImageIndex}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </>
  );
};

export default ImageSetRow;