import axios from 'axios';
import { BaseFilters, PaginatedResponse } from '../types/models';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const createParams = (filters?: BaseFilters): URLSearchParams => {
    const params = new URLSearchParams();

    if (!filters) return params;

    Object.entries(filters).forEach(([key, value]) => {
        if (value === undefined || value === '') return;

        if (key === 'countries' && Array.isArray(value)) {
            value.forEach(countryName => {
                if (countryName) {
                    params.append('countries', countryName);
                }
            });
            return;
        }

        if (Array.isArray(value)) {
            value.forEach(v => params.append(key, v.toString()));
            return;
        }

        if (typeof value === 'boolean') {
            params.append(key, value.toString());
            return;
        }

        params.append(key, value.toString());
    });

    return params;
};

export const fetchFromApi = async <T>(
    endpoint: string,
    filters?: BaseFilters
): Promise<PaginatedResponse<T>> => {
    try {
        const params = createParams(filters);
        const response = await axios.get<PaginatedResponse<T>>(`${BASE_URL}/api/${endpoint}/`, {
            params,
            headers: {
                'Accept': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('API Error:', {
                message: error.message,
                status: error.response?.status,
                data: error.response?.data
            });
        }
        throw error;
    }
};