import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
    Box,
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    Button,
    MenuItem,
    Alert
} from '@mui/material';
import { Send } from 'lucide-react';

interface ContactFormData {
    name: string;
    email: string;
    category: string;
    message: string;
}

const CATEGORY_OPTIONS = [
    { value: 'general', label: 'Yleinen kysymys' },
    { value: 'technical', label: 'Tekninen ongelma' },
    { value: 'content', label: 'Sisältöön liittyvä' },
    { value: 'other', label: 'Muu asia' }
];

const ContactPage: React.FC = () => {
    const [submitSuccess, setSubmitSuccess] = React.useState(false);
    const { control, handleSubmit, reset, formState: { errors } } = useForm<ContactFormData>({
        defaultValues: {
            name: '',
            email: '',
            category: '',
            message: ''
        }
    });

    const onSubmit = async (data: ContactFormData) => {
        try {
            console.log('Form submitted:', data);
            setSubmitSuccess(true);
            reset();
            setTimeout(() => setSubmitSuccess(false), 5000);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <Box>
            <Box sx={{
                height: { xs: '200px', sm: '300px' },
                backgroundImage: 'url(/images/background.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }} />

            <Container maxWidth="lg" sx={{ mt: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={{ xs: 3, md: 4 }}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ pr: { md: 4 } }}>
                                    <Typography variant="h6" gutterBottom>
                                        Meistä
                                    </Typography>
                                    <Typography paragraph>
                                        Tarsiger on perustettu toukokuussa 2002 esittelemään Suomessa tavattuja lintuja. Sivuston viralliset lintuhavainnot valitaan normaalista poiketen erilaisiksi. Kuvien sivusto on ulkoasultaan on taustatietoa ja tuttuilta se löytyä yhtä tarkemmissa puolinäyttäisestä, ja Tarsiger edullisimpiin siis missäsi petini palvella. Toki kuvat ja muuta materiaalista otetaan tärkelysi miksi voidaan myös porataan ulkoasopettiin henkilöistä ks. eriyen.
                                    </Typography>
                                    <Typography paragraph>
                                        Tarsiger-sivusto jakaa tekevät katua omia rahvityksenä, mikä mahdollistaa sivujen nopean päivityksen - uusia kuvia tulee joka päivä. Havaintoja piirretää Mike Brown.
                                    </Typography>
                                    <Typography>
                                        Kuvien tallennetmin noudattaa Howard & Moore:n vuosista ilmestynyttä järjestystapaa. Lintu, Howard ks. June 2, 2008. WP-lajistoon osalta noudattelaan AERC:n täfautuksia. Poikkeavahinta Lucifolasta siirretystä Tarsigeretta on viritelty(A Tarsiger nyrkkeilä, jota on jäljetty otetellaan verkkalle sukunnisvoivat!
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    component="img"
                                    src="/images/tarsiger.png"
                                    alt="Tarsiger Bird"
                                    sx={{
                                        width: '100%',
                                        height: { xs: '300px', md: '100%' },
                                        objectFit: 'cover',
                                        transform: 'scaleX(-1)',
                                        borderRadius: 2
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Card>
                            <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                                <Typography variant="h5" gutterBottom>
                                    Ota yhteyttä
                                </Typography>
                                <Typography sx={{ mb: 4 }}>
                                    Onko sinulla kysyttävää tai palautetta? Täytä alla oleva lomake, ja otamme sinuun yhteyttä
                                    mahdollisimman pian.
                                </Typography>

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={2}>
                                        {submitSuccess && (
                                            <Grid item xs={12}>
                                                <Alert severity="success">
                                                    Viesti lähetetty onnistuneesti!
                                                </Alert>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                name="name"
                                                control={control}
                                                rules={{ required: 'Nimi on pakollinen' }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        label="Nimi"
                                                        fullWidth
                                                        error={!!errors.name}
                                                        helperText={errors.name?.message}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                name="email"
                                                control={control}
                                                rules={{
                                                    required: 'Sähköposti on pakollinen',
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: 'Virheellinen sähköpostiosoite'
                                                    }
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        label="Sähköposti"
                                                        fullWidth
                                                        error={!!errors.email}
                                                        helperText={errors.email?.message}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="category"
                                                control={control}
                                                rules={{ required: 'Valitse kategoria' }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        select
                                                        label="Kategoriat"
                                                        fullWidth
                                                        error={!!errors.category}
                                                        helperText={errors.category?.message}
                                                    >
                                                        {CATEGORY_OPTIONS.map(option => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="message"
                                                control={control}
                                                rules={{ required: 'Viesti on pakollinen' }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        label="Viesti"
                                                        multiline
                                                        rows={4}
                                                        fullWidth
                                                        error={!!errors.message}
                                                        helperText={errors.message?.message}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    endIcon={<Send size={18} />}
                                                >
                                                    Lähetä
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ContactPage;